import $ from 'jquery';
import 'slick-carousel';
import { isTouchDevice } from './device';

var defaultConfig = {
    prevArrow: '<div class="carousel-prev"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"><circle cx="14" cy="14" r="14" fill="white" stroke="white" stroke-width="2"/><g transform="translate(14.5 18.5) rotate(225)"><line x1="0.1" y2="6" transform="translate(6 0)" stroke="black" stroke-width="1" stroke-linecap="round"/><line x1="6" y1="0" transform="translate(0 0)" stroke="black" stroke-width="1" stroke-linecap="round"/></g></svg></div>',
    nextArrow: '<div class="carousel-next"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"><circle cx="14" cy="14" r="14" fill="white" stroke="white" stroke-width="2"/><g transform="translate(13.5 9.5) rotate(45)"><line x1="0.1" y2="6" transform="translate(6 0)" stroke="black" stroke-width="1" stroke-linecap="round"/><line x1="6" y1="0" transform="translate(0 0)" stroke="black" stroke-width="1" stroke-linecap="round"/></g></svg></div>',
    rows: 0,
};

function initSlick(el, config) {
    if (el.parent('.js-scroll-box').length && isTouchDevice()) {
        return;
    }
    el.on('init setPosition', function () {
        el.parent('.js-scroll-box').toggleClass('scroll', !el.hasClass('slick-slider'));
        el.addClass('container');
        el.closest('.experience-component.experience-layouts-row').addClass('overflow-hidden');
        el.closest('.experience-component.experience-layouts-row').find('.slick-list').addClass('overflow-visible');
    });

    el.slick(config);
}

$('[data-slick]').each(function () {
    var config = $.extend({}, defaultConfig, $(this).data('slick') || {});
    initSlick($(this), config);
});

$('.carousel--ProductTiles .product-listing-carousel').each(function () {
    let parent = $(this);
    if (!parent.data('ajax') || !parent.data('limit') || !parent.data('ajax-slick')) {
        return true;
    }
    $.ajax({
        url: parent.data('ajax') + '&limit=' + parent.data('limit'),
        success: function (data) {
            var config = $.extend({}, defaultConfig, parent.data('ajax-slick') || {});
            parent.html(data);
            initSlick(parent, config);
        },
    });
});

$(window).on('resize', function () {
    $('.carousel--ProductTiles .product-listing-carousel:not(.slick-initialized)[data-ajax-slick]').each(function () {
        let config = $.extend({}, defaultConfig, $(this).data('ajax-slick') || {});
        initSlick($(this), config);
    });
    $('[data-slick]:not(.slick-initialized)').each(function () {
        let config = $.extend({}, defaultConfig, $(this).data('slick') || {});
        initSlick($(this), config);
    });
    $('[data-einstein-slick]:not(.slick-initialized)').each(function () {
        let config = $.extend({}, defaultConfig, $(this).data('einstein-slick') || {});
        initSlick($(this), config);
    });
});
